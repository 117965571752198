<template>
  <div>
    <div class="table-head" >
      <div class="table-search">
          <div class="search-item">
            <span>用户信息:</span>
            <el-input v-model="searchOption.userInfo" placeholder="请输入用户信息"></el-input>
          </div>
          <div class="search-item">
            <span>助手名称:</span>
            <el-select v-model="searchOption.robot" value-key="RobotID" placeholder="全部">
               <el-option v-for="item in robots" :key="item.RobotID" :label="item.NickNames" :value="item"></el-option>
            </el-select>
          </div>
          <div class="search-item">
            <span>成为好友时间:</span>
            <el-date-picker
              v-model="searchOption.dateValue"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
          <div class="search-item">
            <span>是否邀请:</span>
            <el-select v-model="searchOption.invite">
              <el-option
                v-for="item in status"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="search-item">
            <span>发送邀请时间:</span>
            <el-date-picker
              v-model="searchOption.inviteDate"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
          <div class="search-item">
            <span>群聊名称:</span>
            <el-input v-model="searchOption.groupName" placeholder="请输入群聊名称"></el-input>
          </div>
          <div class="search-item">
            <span>是否入群:</span>
            <el-select v-model="searchOption.joinGroup">
              <el-option
                v-for="item in status"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div>
            <el-button type="primary" @click="searchAction">搜索</el-button>
            <el-button @click="resetAction">重置</el-button>
          </div>
      </div>

    </div>
    <div class="line-height"></div>
    <div class="table-box">
      <el-table :data="tableData" style="width: 100%" border @sort-change="changeSort">
        <el-table-column label="用户信息" prop="">
          <template #default="scope">
            <div class="wrap-cell">
              <img :src="scope.row.UserHeadImg" alt="">
              <div>{{scope.row.UserName}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="RobotName" label="助手名称">
          <template #default="scope">
            <div>{{scope.row.RobotName}}({{scope.row.WxAlias}})</div>
          </template>
          
        </el-table-column>
        <el-table-column prop="FollowUserCreatetime" label="成为好友时间" sortable='custom'></el-table-column>
        <el-table-column prop="InvitedTime" label="发送邀请时间" sortable='custom'></el-table-column>
        <el-table-column prop="IsInvite" label="是否邀请" ></el-table-column>
        <el-table-column prop="GroupName" label="群聊名称"></el-table-column>
        <el-table-column prop="IsJoinGroup" label="是否入群" ></el-table-column>
      </el-table>
      <div class="foot-wrap">
        <TableFoot
          :pagination="pageObj"
          @changePage="changePage"
          @changeSize="changeSize"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import TableFoot from "@/components/TableFoot";
import { ElMessageBox, ElMessage } from "element-plus";
import {PromotionTaskGetLog} from '@/helper/promotion'
import {GetRobotSimpleList} from '@/helper/robot'
import tools from "@/assets/js/tools";

export default {
  name: "GroupEditLog",
  components: {
    TableFoot,
  },
  setup() {
    const router = useRouter();
    const tableData = ref([]);

    onMounted(() => {
      searchAction();
      GetRobotSimpleList({Name:""}).then(res=>{
        robots.value=[{SerialNo:'',NickName:'全部',WxAlias:''}].concat(res.List||[])
        for(let item of robots.value){
          item.NickNames=item.NickName+'('+item.WxAlias+')'
          let str='全部()'
          if(str.indexOf(item.NickNames)>-1){
            item.NickNames=item.NickName
          }
        }
      })
    });
    /******************点击事件*********************/

    /**********************搜索***************************/
    const robots = ref([]);
    const status= [
      { label: "不限", value: -1 },{ label: "是", value: 1 },
      { label: "否", value: 2 }]
    const searchOption = reactive({
      userInfo: "",
      robot: {},
      dateValue:"",
      groupName:"",
      canInvite:0,
      invite:-1,
      joinGroup:-1,
      inviteDate:"",
      orderBy:1
    });

    //执行搜索
    function searchAction() {
      let start = "";
      let end = "";
      if (searchOption.dateValue) {
        start = tools.GMTToStr(searchOption.dateValue[0],2);
        end = tools.GMTToStr(searchOption.dateValue[1],2);
      }
      let param={
        PageNum:pageObj.PageIndex,
        PageSize:pageObj.PageSize,
        UserName:searchOption.userInfo,
        GroupName:searchOption.groupName,
        RobotSerialNo:searchOption.robot.SerialNo,
        Start:start,
        End:end,
        IsInvite:searchOption.invite,
        IsJoinGroup:searchOption.joinGroup,
        InvitedStart:searchOption.inviteDate&&searchOption.inviteDate.length>0?tools.GMTToStr(searchOption.inviteDate[0],2):'',
        InvitedEnd:searchOption.inviteDate&&searchOption.inviteDate.length>0?tools.GMTToStr(searchOption.inviteDate[1],2):'',
        OrderBy:searchOption.orderBy
      }
      PromotionTaskGetLog(param).then(res=>{
        console.log(res);
        tableData.value=res.List
        tableData.value.forEach(item=>{
          item.IsInvite=item.IsInvite==1?'是':'否'
          item.IsJoinGroup=item.IsJoinGroup==1?'是':'否'
        })
        pageObj.TotalRow=res.TotalCount
      })
    }

        //排序
    const changeSort=(obj)=>{
        pageObj.PageIndex = 1
       if(obj.order == "descending"&&obj.prop=='InvitedTime'){
        searchOption.orderBy = 4;
        searchAction();
      } else if(obj.order == "descending"&&obj.prop=='FollowUserCreatetime'){
        searchOption.orderBy = 2;
        searchAction();
      }else if(obj.order == "ascending"&&obj.prop=='InvitedTime'){
        searchOption.orderBy = 3;
        searchAction();
      } else if(obj.order == "ascending"&&obj.prop=='FollowUserCreatetime'){
        searchOption.orderBy = 1;
        searchAction();
      }  else {
        searchOption.orderBy = 1;
        searchAction();
      }      
    }

    //重置条件
    function resetAction() {
      searchOption.userInfo="",
      searchOption.robot={},
      searchOption.dateValue="",
      searchOption.groupName="",
      searchOption.canInvite=0,
      searchOption.invite=-1,
      searchOption.joinGroup=-1
      searchOption.inviteDate =""
      searchOption.orderBy = 1
      searchAction();
    }

    /****************TableFoot*******************/
    const pageObj = reactive({
      TotalRow: 0,
      PageIndex: 1,
      PageSize: 50,
    });
    //页码改变
    function changePage(index) {
      pageObj.PageIndex = index;
      searchAction();
    }
    function changeSize(size) {
      pageObj.PageSize = size;
      pageObj.PageIndex = 1;
      searchAction();
    }

    return {
      tableData,
      robots,
      status,
      searchOption,
      resetAction,
      searchAction,
      changePage,
      changeSize,
      pageObj,
      changeSort
    };
  },
};
</script>
<style lang="scss" scoped>
.table-search{
    font-size: 14px;
    justify-content: space-between;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    grid-gap: 10px;
    margin: 8px;
    .search-item{
        display: flex;
        align-items: center;
    }
}
.wrap-cell{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 10px;
  img{
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
}
.action-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    width: 15px;
    height: 13px;
    margin-left: 2px;
  }
}


.foot-wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    margin-left: 3px;

    .selectAll {
      margin: 0 10px;
    }
  }
}
.list-title {
  font-size: 14px;
}
.list-title div span {
  color: $color-common;
}
:deep() .table-search>.search-item>.el-input__inner{
    width: 206px;
}
</style>